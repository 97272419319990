<template>
  <div>
    <user-sidebar />
    <div class="relative md:ml-64 bg-gray-200">
      <div>
        <user-navbar />
      </div>
      <div class="px-4 md:px-10 mx-auto w-full h-full">
        <router-view />
      </div>
      <div>
        <footer-user />
      </div>
    </div>
  </div>
</template>
<script>
import UserNavbar from '@/components/Navbars/UserNavbar.vue'
import UserSidebar from '@/components/Sidebar/UserSidebar.vue'
import FooterUser from '@/components/Footers/FooterUser.vue'
export default {
  name: 'admin-layout',
  components: {
    UserNavbar,
    UserSidebar,
    FooterUser
  }
}
</script>
