<template>
  <!-- Navbar -->
  <nav
    class="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-no-wrap md:justify-start flex items-center p-4"
  >
    <div
      class="w-full mx-autp items-center flex justify-between md:flex-no-wrap flex-wrap md:px-10 px-4"
    >
      <!-- Brand -->
      <a
        class="text-grey-500 text-sm uppercase hidden lg:inline-block font-semibold"
        href="javascript:void(0)"
      >
        Сводная панель
      </a>
    </div>
  </nav>
  <!-- End Navbar -->
</template>
<script>
export default {
  components: {}
}
</script>
